import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/background"

const WorkPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWorkJson {
        nodes {
          link
        }
      }
    }
  `)

  const embed = data.allWorkJson.nodes.map(node => {
    console.log(node.link)
    const link: string = node.link
    const serviceRegex: RegExp = /(\w+)\.(\w+)/
    const service: string = link.match(serviceRegex)[0]
    const id: string = link.match(/(?:\/\/)(\S+)\.(\w+)(?:\/)(.+)/)[3]
    let framelink: string

    switch (service) {
      case "youtu.be":
      case "youtube.com":
        framelink = `https://www.youtube.com/embed/${id}?autoplay=0`
        break
      case "vimeo.com":
        framelink = `https://player.vimeo.com/video/${id}`
        break
    }

    return (
      <iframe src={framelink} width="640" height="360" frameBorder="0"></iframe>
    )
  })

  return (
    <Layout>
      <SEO title="Work" description="Our previous work" />
      <Background image="bokehbohm.jpg" className="landing bg-dark" mode="scroll">
        <h1>WORK</h1>
      </Background>
      <div className="content">
        <div className="container text-center">{embed}</div>
      </div>
    </Layout>
  )
}
export default WorkPage
